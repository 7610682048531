import axios from 'axios'
import { googleLogout } from "vue3-google-login"
import { useStorage } from "vue3-storage";
import { useToast } from "vue-toastification";
export function useAuth() {
    const storage = useStorage()
    const toast = useToast();
    const logout = async () => {
        try {
            const response = await axios.get('logout');
            if (response.data.status === 'success') {
                clearStorage();
            }
        } catch (error) {
            console.log("Main Error: ",error)
            if (error.response) {
                if (error.response.status === 422) {
                    // Handle 422 error
                    toast.error(error.response.data.message);
                } else if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
                    clearStorage();
                }
            }
        }
    };
    function clearStorage() {
        googleLogout();
        // Remove storage items
        storage.removeStorageSync("user")
        storage.removeStorageSync("token")
        storage.removeStorageSync("business")
        // Reload the page
        window.location.href = process.env.VUE_APP_URL;
    }
    return {
        logout,
        // getLocation,
    }
}