import { defineStore } from 'pinia'
export const useNotificationsStore = defineStore('notifications', {
  state: () => ({ 
    notifications:[],
    unreadNotifications: 0
  }),
  actions: {
    async getNotifications() {
      const resp = await this.axios.get('notifications')
      if (resp.data.status === 'success') {
        // console.log(resp.data.data)
        this.notifications = resp.data.data.allNotifications
        this.unreadNotifications = resp.data.data.unreadNotifications
      }
    }
    
  }
})