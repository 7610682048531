<template>
    <div v-if="storage.getStorageSync('token')" class="float-end noti-dropdown" id="notifications">
        <button type="button" class="nav-link position-relative border-0 bg-transparent" :class="id == 'dashboardNotificaions' ? 'mt-2':''" aria-haspopup="true" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false">
            <fa :icon="['far','bell']" class="fs-5 m-0" />
            <span class="position-absolute  translate-middle badge rounded-pill bg-danger " :class="id == 'dashboardNotificaions' ? 'top-1 start-90':'px-1 top-0 start-100'" v-show="store.unreadNotifications > 0" style="min-width: 20px;">
                <span v-if="store.unreadNotifications < 100">{{store.unreadNotifications}}</span>
                <span v-else>99+</span>
            </span>
        </button>
        <div class="dropdown-menu p-0">
            <div class="list-group">
                <div class="d-flex align-items-center p-3 border-bottom ">
                    <div class="col p-0">
                        <h6 class="m-0 text-muted fw-bold"> Notifications &nbsp;<span>({{store.notifications.length}})</span> </h6>
                    </div>
                    <div class="col-auto p-0">
                        <a href="javascript:void(0);" v-on:click="markAsRead('all')" class="small fw-bold">Mark all as read</a>
                    </div>
                </div>
                <div class="noti_div">
                    <div class="no_noti" v-if="store.notifications.length == 0">
                        <img src="@/assets/images/icons/notifications.svg" style="width: 45px">
                        <h6 class="pt-2 text-muted">No Notifications</h6>
                        <p class="text-muted">Book a professional for you.</p>
                    </div>
                    <a v-for="(noti,i) in store.notifications" :key="i" v-on:click="markAsRead(noti.id)" :href="noti.data.link ? app_url+noti.data.link:'javascript:void(0);'" class="list-group-item list-group-item-action" :class="noti.read_at ? '':'unread_noti'">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img v-if="noti.data.noti_type == 'welcome'" src="@/assets/images/icons/notifications.svg" style="width: 30px;">
                                <img v-else-if="noti.data.noti_type == 'booking'" src="@/assets/images/icons/appointment.svg" style="width: 30px;">
                                <img v-else-if="noti.data.noti_type == 'queue'" src="@/assets/images/icons/queue.svg" style="width: 30px;">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">
                                    {{noti.data.title}}
                                    <p class="mb-0 text-muted text-end" style="font-size: 12px;display: inline;float: right;"><fa :icon="['far','clock']" class="me-1" />{{moment(new Date(noti.created_at)).fromNow(true)}} ago </p>
                                </h6>
                                <div class="font-size-12">
                                    <p class="mb-1" style="font-size: 13px;" v-if="noti.data.description">{{noti.data.description}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <!-- <a href="javascript: void(0);" class="list-group-item list-group-item-action" >
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/appointment.svg" style="width: 30px;">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">Appointment Reminder</h6>
                                <div class="font-size-12">
                                    <p class="mb-1">Your appointment with ABC Beauty Sal...</p>
                                    <p class="mb-0 text-muted"><fa :icon="['far','clock']" class="me-1" />Yesterday</p>
                                </div>
                            </div>
                            <div class="flex-grow-2 me-2 mt-1">
                                <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                            </div>
                        </div>
                    </a>
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/messaging.svg" style="width: 30px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">New Message</h6>
                                <div class="font-size-12">
                                    <p class="mb-0">You have a new message.</p>
                                    <p class="mb-0 text-muted"><fa :icon="['far','clock']" class="me-1" />1 hr ago</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a unread_noti">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/appointment.svg" style="width: 30px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">Appointment on the way!</h6>
                                <div class="font-size-12">
                                    <p class="mb-0">You have successfully booked an...</p>
                                    <p class="mb-1"><router-link :to="{name:'BusinessDashboard'}" target='_blank'>Go to dashboard</router-link></p>
                                    <p class="mb-0 text-muted"><fa :icon="['far','clock']" class="me-1" />1 hr ago</p>
                                </div>
                            </div>
                            <div class="flex-grow-2 me-2 mt-1">
                                <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                            </div>
                        </div>
                    </a>
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/notifications.svg" style="width: 0px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">Birthday Reminder</h6>
                                <div class="font-size-12">
                                    <p class="mb-1">OnDaQ wishes you a happy birthday!</p>
                                    <p class="mb-0 text-muted"><fa :icon="['far','clock']" class="me-1" />This Week</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a unread_noti">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/queue.svg" style="width: 30px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">Queue Reminder</h6>
                                <div class="font-size-12">
                                    <p class="mb-1">You have successfully joined the waitlist....</p>
                                    <p class="mb-0 text-muted"><fa :icon="['far','clock']" class="me-1" />This Month</p>
                                </div>
                            </div>
                            <div class="flex-grow-2 me-2 mt-1">
                                <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                            </div>
                        </div>
                    </a> -->
                    
                    <!-- <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                            <img src="@/assets/images/booking/confirmation.svg" style="width: 50px">
                            <h6 class="mb-1">List group item heading</h6>
                            <small>3 days ago</small>
                        </div>
                        <p class="mb-1">Some placeholder content in a paragraph.</p>
                        <small>And some small print.</small>
                    </a> -->
                </div>
                <!-- <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">List group item heading</h6>
                        <small>3 days ago</small>
                    </div>
                    <p class="mb-1">Some placeholder content in a paragraph.</p>
                    <small>And some small print.</small>
                </a> -->
                <div class="p-2 border-top"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import moment from 'moment'
import { useNotificationsStore } from '@/stores/notifications'
import { useStorage } from "vue3-storage";
import axios from 'axios'
import $ from 'jquery'
const storage = useStorage()
const store = useNotificationsStore()
if (storage.getStorageSync('token')) {
    if(store.notifications.length == 0){
        store.getNotifications()
    }
}
const app_url = ref(process.env.VUE_APP_URL)
const updateNotification = (id) => {
    axios.get('notifications/detail/'+id).then(function (response) {
        console.log(response.data)
        if (response.data.status == 'success') {
            store.notifications.unshift(response.data.data);
            store.unreadNotifications = parseInt(store.unreadNotifications) + 1
        }
    })
    .catch(error => {
        if (error.response) {
            console.log(error.response.data.message)
        }
    });
}
const markAsRead = (id) => {
    axios.get('notifications/mark_as_read',{params: {id:id}}).then(function (response) {
        console.log(response.data)
        if (response.data.status == 'success') {
            updateNotificationReadStatus(id)
        }
    })
    .catch(error => {
        if (error.response) {
            console.log(error.response.data.message)
        }
    });
}
function updateNotificationReadStatus(id) {
    for (var i = 0; i < store.notifications.length; i++) {
        if (id != 'all') {            
            if (store.notifications[i].id === id) {
                store.notifications[i].read_at = new Date;
                store.unreadNotifications = store.unreadNotifications - 1
                break; // Exit the loop once the user is found and updated
            }
        }else if(id.toLowerCase() == 'all'){
            if(!store.notifications[i].read_at) {
                store.notifications[i].read_at = new Date
            }
        }
    }
    if(id.toLowerCase() == 'all'){
        store.unreadNotifications = 0
    }
}
defineProps({ 
    id:{
        type: String,
        default() {
            return 'mainNotificaions';
        }
    } 
});

onMounted(() => {
    if (storage.getStorageSync('token')) {
        if(store.notifications.length == 0){
            store.getNotifications()
        }

        window.Echo.private('users.' + storage.getStorageSync('user').id)
        .notification((notification) => {
            console.log("notification recieved", notification);
            if ($('#notifications div.dropdown-menu').hasClass('show')) {
                updateNotification(notification.id);
            }else{
                store.getNotifications()
            }
        });
    }
});

</script>

<style scoped>
    .list-group-item{
        padding: .7rem !important;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-bottom: 1px solid #eee;
    }
    .unread_noti{
        background-color: #f5f8fa !important
    }
    .noti_div{
        min-height: 400px;
        max-height: 400px;
        overflow-y: auto;
    }
    .noti_div::-webkit-scrollbar-thumb,.noti_div::-webkit-scrollbar-track {
      visibility: hidden;
    }
    .noti_div:hover::-webkit-scrollbar-thumb,.noti_div:hover::-webkit-scrollbar-track {
      visibility: visible;
    }

    /* .noti_div:hover{
        overflow-y: auto;
    } */
    .no_noti{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
        margin-top: 25%
    }
    .no_noti p{
        max-width: 250px
    }
    /* .noti_a{
        min-height: 90px;
        max-height: 110px
    } */
</style>