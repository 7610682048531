<template>
    <div v-if="storage.getStorageSync('token')" class="float-end noti-dropdown">
        <button type="button" class="nav-link position-relative border-0 bg-transparent" :class="id == 'dashboardNotificaions' ? 'mt-2':''" data-bs-toggle="dropdown" aria-expanded="false">
            <Tooltip :tooltipText="'Messages'" position="bottom" style="font-size:15px"><fa :icon="['far','envelope']" class="fs-5 m-0" /></Tooltip>
            <span class="position-absolute  translate-middle badge rounded-pill bg-danger " :class="id == 'dashboardNotificaions' ? 'top-1 start-90':'px-1 top-0 start-100'" style="min-width: 20px;">
                <!-- <span v-if="store.unreadNotifications < 100">{{store.unreadNotifications}}</span> -->
                <span>9+</span>
            </span>
        </button>
        <div class="dropdown-menu p-0">
            <div class="list-group">
                <div class="d-flex align-items-center p-3 border-bottom">
                    <div class="col p-0">
                        <h6 class="m-0 text-muted fw-bold"> <fa :icon="['far','envelope']" class="fs-6 me-1" /> Inbox &nbsp;<span>(0)</span> </h6>
                    </div>
                    <div class="col-auto p-0">
                        <a href="#!" class="small fw-bold"> View All</a>
                    </div>
                </div>
                <div class="noti_div">
                    <!-- <div class="no_noti">
                        <img src="@/assets/images/icons/notifications.svg" style="width: 45px">
                        <h6 class="pt-2 text-muted">No Notifications</h6>
                        <p class="text-muted">Browse our amazing catalog of Gigs or offer your talent on Fiverr.</p>
                    </div> -->
                    <a href="#" class="list-group-item list-group-item-action">
                        <div class="d-flex">
                            <div class="avatar-xs me-3">
                                <img src="@/assets/images/icons/avatr.png" style="width: 40px;">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">
                                    my-beauty-saloon
                                    <p class="mb-0 text-muted text-end" style="font-size: 12px;display: inline;float: right;"><fa :icon="['far','clock']" class="me-1" />2 min ago </p>
                                </h6>
                                <div class="font-size-12">
                                    <p class="mb-1" style="font-size: 13px;" >Hi </p>
                                </div>
                            </div>
                        </div>
                    </a>
                    
                </div>
                <div class="p-2 border-top"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Tooltip from "@/components/common/Tooltip.vue"
import { useStorage } from "vue3-storage";

const storage = useStorage()


defineProps({ 
    id:{
        type: String,
        default() {
            return 'mainNotificaions';
        }
    } 
});
</script>